import gql from 'graphql-tag';

export const UPDATE_USER = gql`
  mutation UPDATE_USER($id: ID!, $ehpadId: ID, $email: String!, $password: String, $role: String!) {
    updateUser(
      input: {
        id: $id
        email: $email
        ehpadId: $ehpadId
        password: $password
        role: $role
      }
    ) {
      user {
        id
        email
        role
        ehpad {
          id
          name
          email
          shortname
          color
          allSettings
        }
      }
    }
  }
`;

export const UPDATE_CURRENT_USER_TERMS_OF_SERVICE = gql`
  mutation UPDATE_CURRENT_USER_TERMS_OF_SERVICE($termsOfService: Boolean!) {
    updateCurrentUserTermsOfService(
      input: {
        termsOfService: $termsOfService
      }
    ) {
      user {
        id
        email
        role
        termsOfService
        lastTicket {
          id
          email
          phone
          lastName
          firstName
        }
        ehpad {
          id
          name
          email
          shortname
          color
          allSettings
        }
      }
    }
  }
`;

export const UPDATE_CURRENT_USER_PASSWORD = gql`
  mutation UPDATE_CURRENT_USER_PASSWORD($password: String!, $passwordConfirmation: String!) {
    updateCurrentUserPassword(
      input: {
        password: $password
        passwordConfirmation: $passwordConfirmation
      }
    ) {
      user {
        id
        email
        role
        termsOfService
        lastTicket {
          id
          email
          phone
          lastName
          firstName
        }
        ehpad {
          id
          name
          email
          shortname
          color
          allSettings
        }
      }
    }
  }
`;


export const CREATE_USER = gql`
  mutation CREATE_USER($ehpadId: ID, $email: String!, $password: String!, $role: String!) {
    createUser(
      input: {
        email: $email
        ehpadId: $ehpadId
        password: $password
        role: $role
      }
    ) {
      user {
        id
        email
        role
        ehpad {
          id
          name
          shortname
        }
      }
    }
  }
`;

export const CREATE_TICKET = gql`
  mutation CREATE_TICKET(
    $ehpadId: ID,
    $email: String!,
    $description: String!,
    $category: String!,
    $phone: String!,
    $lastName: String!,
    $firstName: String!
    $url: String!
  ) {
    createTicket(
      input: {
        email: $email
        ehpadId: $ehpadId
        description: $description
        category: $category
        phone: $phone
        lastName: $lastName
        firstName: $firstName
        url: $url
      }
    ) {
      ticket {
        id
        email
        description
        category
        phone
        lastName
        firstName
        url
        ehpad {
          id
          name
          email
          shortname
        }
      }
    }
  }
`;

