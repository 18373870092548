import gql from 'graphql-tag';

export const UPDATE_ROOM = gql`
  mutation UPDATE_ROOM($id: ID!, $number: String!) {
    updateRoom(
      input: {
        id: $id
        number: $number
      }
    ) {
      room {
        id
        number
        isFree
      }
    }
  }
`;
