import gql from 'graphql-tag';

import apiClient from '../../utilities/api_client';

const globalSettingsState = {
  globalSettings: null,
};

const mutations = {
  SET_GLOBALSETTINGS(state, globalSettings) {
    state.globalSettings = globalSettings;
  },
};

const actions = {
  setGlobalSettings: async ({ commit }) => {
    const { data } = await apiClient.query({
      query: gql` 
        query FETCH_GLOBALSETTINGS {
          globalSettings
        },
      `,
    });
    commit('SET_GLOBALSETTINGS', data.globalSettings);
  },
};

const getters = {
  globalSettings: (state) => state.globalSettings,
};

export default {
  state: globalSettingsState,
  mutations,
  actions,
  getters,
};
