import gql from 'graphql-tag';

export const UPDATE_EHPAD = gql`
  mutation UPDATE_EHPAD($id: ID!, $name: String!, $shortname: String!, $color: String!, $email: String) {
    updateEhpad(
      input: {
        id: $id
        name: $name
        shortname: $shortname
        color: $color
        email: $email
      }
    ) {
      ehpad {
        id
        name
        email
        shortname
        color
        email
      }
    }
  }
`;

export const UPDATE_EHPAD_SETTING = gql`
  mutation UPDATE_EHPAD_SETTING($id: ID!, $state: String!, $rootState: String!, $setting: String!, $value: String!) {
    updateEhpadSetting(
      input: {
        id: $id
        state: $state
        rootState: $rootState
        setting: $setting
        value: $value
      }
    ) {
      ehpad {
        id
        name
        email
        shortname
        color
        email
        allSettings
      }
    }
  }
`;
