<template>
  <app-col-content>
    <b-container fluid>
      <b-row align-h="center">
        <b-col cols="12" md="8">
            <div class="d-flex justify-content-center align-items-center mb-3">
                <span class="text-center">
                    <h5 class="m-0" style="display: inline-block;margin: 0;">
                    Mon profil
                    </h5>
                </span>
            </div>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="6">
          <div class="profile-card p-3 text-center">
              <h6><span class="font-weight-bold">Nom d'utilisateur : </span>{{ currentUser && currentUser.email }}</h6>
              <h6><span class="font-weight-bold">Role : </span>{{ currentUser && currentUser.role }}</h6>
              <h6 v-if="currentUser && currentUser.ehpad !== null">
                <span class="font-weight-bold">EHPAD : </span>
                {{ currentUser && currentUser.ehpad.name }}
              </h6>
              <h6 class="my-4"><span class="font-weight-bold">Changer mon mot de passe : </span></h6>

              <b-form>
                <b-row class="pt-2">
                  <b-col cols="6">
                    <label for="inline-form-input-password" class="text-nowrap">Mot de passe</label>
                  </b-col>
                  <b-col cols="6">
                    <b-input
                      id="inline-form-input-password"
                      size="sm"
                      type="password"
                      v-model="password"
                      :class="{ 'is-invalid': $v.password.$error }"
                      autocomplete="new-password"
                    />
                    <b-form-invalid-feedback
                      >Entrez un mot de passe valide (8 caractères minimum)</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>

                <b-row class="pt-2">
                  <b-col cols="6">
                    <label for="inline-form-repeat-password" class="text-nowrap">Confirmer le mot de passe</label>
                  </b-col>
                  <b-col cols="6">
                    <b-input
                      id="inline-form-repeat-password"
                      size="sm"
                      type="password"
                      v-model="repeatPassword"
                      :class="{ 'is-invalid': $v.repeatPassword.$error }"
                      autocomplete="new-password"
                    />
                    <b-form-invalid-feedback
                      >Les mots de passes doivent correspondre</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>

                <b-button class="mt-3 d-flex mx-auto" variant="primary" size="sm" @click="updateCurrentUserPassword"
                  >Valider</b-button
                >
              </b-form>

              <h6 v-if="message" class="font-weight-bold mt-4"> {{ message }}</h6>

          </div>
        </b-col>
      </b-row>
    </b-container>
  </app-col-content>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import ColContent from '../../components/layout/ColContent.vue';

export default {
  components: {
    appColContent: ColContent,
  },
  mixins: [validationMixin],
  data() {
    return {
      password: '',
      repeatPassword: '',
      message: null,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
    repeatPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
  methods: {
    async updateCurrentUserPassword() {
      this.message = null;
      this.$v.password.$touch();
      this.$v.repeatPassword.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateCurrentUserPassword', {
        password: this.password,
        passwordConfirmation: this.repeatPassword,
      }).then((result) => {
        this.$v.$reset();
        this.resetData();
        this.message = 'Votre mot de passe a bien été modifié';
      }).catch((error) => {
        this.message = 'Erreur inattendue, veuillez rééssayer';
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
    }),
  },
};
</script>

<style lang="scss" scoped>
.profile-card {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
</style>
