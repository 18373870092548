import gql from 'graphql-tag';

export const CREATE_WEIGHT = gql`
  mutation CREATE_WEIGHT($date: String!, $value: Float!, $residentId: ID!) {
    createWeight(input: { date: $date, value: $value, residentId: $residentId }) {
      weight {
        id
        date
        value
      }
    }
  }
`;
