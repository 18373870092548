<template>
    <div>
        <app-residents-table />
        <app-residents-without-room-table />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ResidentsTable from '../../components/residents/ResidentsTable.vue';
import ResidentsWithoutRoomTable from '../../components/residents/ResidentsWithoutRoomTable.vue';

export default {
  components: {
    appResidentsTable: ResidentsTable,
    appResidentsWithoutRoomTable: ResidentsWithoutRoomTable,
  },
  methods: {
    ...mapActions(['setResidents']),
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
  created() {
    this.setResidents(this.currentEhpad.id);
  },
};
</script>

<style lang="scss" scoped>
</style>
