import gql from 'graphql-tag';

export const CREATE_SECTOR = gql`
  mutation CREATE_SECTOR($name: String!, $ehpadId: ID!) {
    createSector(
      input: {
        name: $name
        ehpadId: $ehpadId
      }
    ) {
      sector {
        id
        name
      }
    }
  }
`;

export const UPDATE_SECTOR = gql`
  mutation UPDATE_SECTOR($id: ID!, $name: String!) {
    updateSector(
      input: {
        id: $id
        name: $name
      }
    ) {
      sector {
        id
        name
      }
    }
  }
`;
