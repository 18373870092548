import apiClient from '../../utilities/api_client';
import { FETCH_ROOMS } from '../../graphql/rooms/queries';
import { UPDATE_ROOM } from '../../graphql/rooms/mutations';

const roomState = {
  rooms: [],
};

const mutations = {
  SET_ROOMS(state, rooms) {
    state.rooms = rooms;
  },
  UPDATE_ROOM(state, room) {
    const index = state.rooms.findIndex((e) => e.id === room.id);
    state.rooms.splice(index, 1, room);
  },
};

const actions = {
  setRooms: async ({ commit }, ehpadId) => {
    const { data } = await apiClient.query({
      query: FETCH_ROOMS,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_ROOMS', data.rooms);
  },
  updateRoom: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_ROOM,
      variables: { ...formData },
    });
    const { room } = data.updateRoom;
    commit('UPDATE_ROOM', room);
  },
};

const getters = {
  rooms: (state) => state.rooms,
};

export default {
  state: roomState,
  mutations,
  actions,
  getters,
};
