import gql from 'graphql-tag';

export const FETCH_ROOMS = gql`
    query FETCH_ROOMS($ehpadId: ID!) {
        rooms(ehpadId: $ehpadId) {
            id
            number
            isFree
        }
    }
`;