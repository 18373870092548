import apiClient from '../../utilities/api_client';
import { FETCH_SECTORS } from '../../graphql/sectors/queries';
import { CREATE_SECTOR, UPDATE_SECTOR } from '../../graphql/sectors/mutations';

const sectorState = {
  sectors: [],
};

const mutations = {
  SET_SECTORS(state, sectors) {
    state.sectors = sectors;
  },
  UPDATE_SECTOR(state, sector) {
    const index = state.sectors.findIndex((e) => e.id === sector.id);
    state.sectors.splice(index, 1, sector);
  },
  ADD_SECTOR(state, sector) {
    state.sectors.push(sector);
  },
};

const actions = {
  setSectors: async ({ commit }, ehpadId) => {
    const { data } = await apiClient.query({
      query: FETCH_SECTORS,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_SECTORS', data.sectors);
  },
  createSector: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_SECTOR,
      variables: { ...formData },
    });
    const { sector } = data.createSector;
    commit('ADD_SECTOR', sector);
  },
  updateSector: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_SECTOR,
      variables: { ...formData },
    });
    const { sector } = data.updateSector;
    commit('UPDATE_SECTOR', sector);
  },
};

const getters = {
  sectors: (state) => state.sectors,
};

export default {
  state: sectorState,
  mutations,
  actions,
  getters,
};
