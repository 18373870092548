import gql from 'graphql-tag';

export const FETCH_USERS = gql`
    query FETCH_USERS {
        users {
            id
            email
            role
            ehpad {
                id
                name
                shortname
            }
        }
    }
`;