import gql from 'graphql-tag';

export const CREATE_RESIDENT_STATUS = gql`
  mutation CREATE_RESIDENT_STATUS(
    $residentId: ID!
    $residentStatus: ResidentStatusAttributes!
    $statusCategory: StatusCategoryAttributes!
  ) {
    createResidentStatus(
      input: { 
        residentId: $residentId, 
        residentStatus: $residentStatus,
        statusCategory: $statusCategory,
      }
    ) {
      residentStatus {
        id
      }
    }
  }
`;